import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App_EC from './eContainer/App';
import App_IoT from './IoT/App';
import App_EE from './eEfficiency/App';
import App_ES from './eSpot/App';
import Navigation from './Navigation';
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(
  <React.StrictMode>
	<HttpsRedirect>
	<BrowserRouter>
		<Switch>
			<Route exact path='/' component={Navigation} />
			<Route path='/iot' component={App_IoT} title="IoT"/>
			<Route path='/econtainer' component={App_EC} title="eContainer"/>
			<Route path='/eefficiency' component={App_EE} title="eEfficiency"/>
			<Route path='/espot' component={App_ES} title="eSpot"/>
		</Switch>
	</BrowserRouter>
	</HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
