import React from 'react';
import styled from '@emotion/styled'
 
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import logo from './sterna.png';

const Main = styled.div`
        padding: 10pt 10pt 20pt 10pt;
        text-align: center;
        background-color: #a3a3a3;
        width: 400px;
        margin: 0 auto;
        margin-top: 50px;
        font-size: 16pt;

        border: 0.08em solid #000000;
        border-radius: 5pt;
    `;
	
const StateMessage = styled.div`
        padding-bottom: 10pt;
        font-size: 20pt;
        font-weight: 500;
    `

const Navigation = () => {
    return (
		<div>
			<Helmet>
				<title>Sterna Dashboards</title>
				<link rel="icon" href="/st.png" />
			</Helmet>
	
			<div height="300" align="center">
				<br /><br />
                        	<img src={logo} width="300" alt="Sterna Logo" />
                        </div>

			<Main>
				<StateMessage>Choose Platform</StateMessage> 
					<ul>
						<Link to="/iot">
							<button className="button2">
								<p>Weather Stations</p>
							</button>
						</Link>
					</ul>
					<ul>
						<Link to="/econtainer">
							<button className="button2">
								<p>eDelivery</p>
							</button>
						</Link>
					</ul>
					<ul>
						<Link to="/eefficiency">
							<button className="button2">
								<p>eEfficiency</p>
							</button>
						</Link>
					</ul>
					<ul>
						<Link to="/espot">
							<button className="button2">
								<p>eSpot</p>
							</button>
						</Link>
					</ul>
			</Main>
		</div>
    );
}
 
export default Navigation;

// https://www.quora.com/What-are-some-good-ways-to-extract-one-single-column-from-a-DynamoDB-table
