import React, {useState, useContext, useEffect} from 'react';
import styled from '@emotion/styled'
import './choice_menu.css';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { SelectContext } from './context';
import InstContainers from '../Layout/inst_blocks';
import DistGraph from '../DistancesGraphs/distance_graph';
import HighGraph from '../DistancesGraphs/altitude_graph';

// AWS functions
import {scanTable, get_docClient} from './../../AWS-SDK/AWS';

let docClient = get_docClient();

var sem = 0;

const Selection = props => {

	const Button = styled.div ` 
        background-color:#ffffff;
        margin: 0 auto;
    `
	
	const ButtonSection = styled.div ` 
        margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px 5px 10px 5px;
    `
	
	const Section = styled.div`
        width: 70%; /* width of the content of the whole page */
        margin: 0 auto;
        margin-bottom: 150px;
    `
	
	const CheckboxsContainer = styled.div ` 
        background-color: #dddddd;

        justify-content: space-between;
        color: black;
        font-size: 16px;
        list-style: none;
        line-height: 20px;

        padding: 5px 10px 5px 10px;
        border-radius: 0pt;
        border-color: #394475;
        border-width: 0.9pt;
        border-style: solid;

        margin: 0 auto;
        border-top: 10px;
        top: 80px;

        z-index: 102;
    `
	
	const SectionHeader = styled.div ` 
		background-color: #394475;

		display: flex;
		justify-content: space-between;
		color: black;
		font-size: 20px;
		list-style: none;
		line-height: 20px;

		padding: 3px 10px 3px 10px;
		border-radius: 0pt;
		border-color: #000000;
		border-width: 0pt;
		border-style: solid;
		top: 50%;

		margin: 0 auto;
		vertical-align: middle;
    `
	
	const SectionGraphs = styled.div ` 
		display: flex;
		justify-content: space-between;
		color: black;
		font-size: 20px;
		list-style: none;
		line-height: 20px;

		padding: 0px;
		border-radius: 0pt;
		border-color: #000000;
		border-width: 0pt;
		border-style: solid;
		top: 55px;

		margin: 0 auto;
		vertical-align: middle;
		z-index: 102;
    `
	
	const SectionData = styled.div ` 
		display: flex;
		justify-content: space-between;
    `
	
	const MarginHeader = styled.div ` 
        margin-top: 4pt;
		margin-bottom: 4pt;
        line-height: 20px;
        color: white;
    `
	  
	// selection contexts
	const {isChoiceSubmitted, setIsChoiceSubmitted, selectedDevices, setSelectedDevices, isCheckAll, setIsCheckAll} = useContext(SelectContext);
	
	// devices lists
	const centrals = [];
	const peripherals = [];
	const centralPeripherals = [];
	const devNames = [];
	
	// instant values
	const [instData, setInstData] = useState(null);
	const [devices, setDevices] = useState(null);
	const [auxState, setAuxState] = useState(null);

	function load_data() {
		scanTable("eSpot_last").then(res => {
			if (res.exist){
				var data = {};
				var devs = [];
				console.log(res.items)
				for (var i in res.items){
					if (res.items[i]['MAC'].length == 12){
						if (!devs.includes(res.items[i]['MAC'])){
							devs.push(res.items[i]['MAC']);
							data[res.items[i]['MAC']] = {};
						}
						if (res.items[i]['Type'] == 'd'){
							data[res.items[i]['MAC']]['Distance'] = Math.round(parseFloat(res.items[i]['Value'])*2)/2;
							data[res.items[i]['MAC']]['Timestamp_d'] = parseInt(res.items[i]['Timestamp']);
						}
						else if (res.items[i]['Type'] == 'h'){
							data[res.items[i]['MAC']]['Altitude'] = Math.round(parseFloat(res.items[i]['Value'])*2)/2;
							data[res.items[i]['MAC']]['Timestamp_h'] = parseInt(res.items[i]['Timestamp']);
						}
					}
				}
				devs.sort();
				setInstData(data);
				setDevices(devs);
			}
			else{
				console.log("Table does not exist!");
			}
			sem -= 1;
			//if (sem == 0){
				sem += 1;
				setTimeout(load_data, 5000);
			//}
		});
	}
	
	useEffect(()=>{
		console.log('Call scan for the first time');
		sem += 1;
		load_data();
	},[]);
	
	useEffect(()=>{
		console.log(instData);
	},[instData]);
	
	//const interval = setInterval(load_data, 5000);
	
	const clickAction = () => {
		setIsChoiceSubmitted(true);
	}
	
	const fClick = (central, peripheral) => {
		var devicesSel = selectedDevices;
		if (devicesSel.includes(peripheral)){
			devicesSel.splice(devicesSel.indexOf(peripheral), 1);
		}
		else {
			devicesSel.push(peripheral);
		}
		setSelectedDevices(devicesSel);
	}
	
	const selectAll = () => {
		setIsCheckAll(!isCheckAll);
		if (isCheckAll){
			setSelectedDevices([]);
		}
		else{
			var devicesSel = []; 
			for (let i in peripherals){
				devicesSel.push(peripherals[i]);
			}
			setSelectedDevices(devicesSel);
		}
	}
	
	const DevicesList = () => {
		var llista_master = [];
		var CPmatrix = [];
		var n = 0;
		for (var central in centralPeripherals){
			CPmatrix[n] = [];
			for (var i=0; i<centralPeripherals[central].length; i++){
				CPmatrix[n][i]=centralPeripherals[central][i];
			}
			n++;
		}
		CPmatrix.map( (submatrix, i) => {
			llista_master.push((<dt key={centrals[i]}>{devNames[centrals[i]]+":"}</dt>));
			llista_master.push((<dt key={centrals[i] +"_gap"}></dt>));
			submatrix.map( (Peripheral, j) => {
				llista_master.push((<dd key={Peripheral}> <input onChange={() => fClick(centrals[i], Peripheral)} type="checkbox" defaultChecked={selectedDevices.includes(Peripheral)}/> {devNames[Peripheral]} </dd>));
				// each line has 3 values and historic button
				//<ButtonSection>
					//<Button onClick={clickAction} className="generalbutton"> Submit </Button>
				//</ButtonSection>
				return null;
			})
			return null;
		})
		return llista_master;
	}
	
	const DataList = () => {
		var llista_master = [];
		for (var i in devices){
			llista_master.push((<dtc style={{border:0}} key={devices[i]}>{devices[i]+":"}</dtc>));
			//llista_master.push((<dt key={devices[i] +"_gap"}></dt>));
			llista_master.push((<dtc key={devices[i] +"_data"}>
									<SectionData>
										<InstContainers device={devices[i]} data={instData} />
									</SectionData>
								</dtc>));
			//llista_master.push((<dd key={devices[i]+"_button"}><Button onClick={clickAction} className="generalbutton"> Historic </Button></dd>));
		}
		return llista_master;
	}

	return (
		<Section>
			{instData && devices ? (
				<>
					<SectionGraphs>
						<DistGraph devices={devices} data={instData}/>
						<HighGraph devices={devices} data={instData}/>
					</SectionGraphs>
					<SectionHeader>
						<MarginHeader>
							{"Devices"}
						</MarginHeader>
					</SectionHeader>
					<CheckboxsContainer>			
						<dlc>
							<DataList />
						</dlc>
					</CheckboxsContainer>
				</>
			) : (<div> Loading environment... </div>)}
		</Section>
	);
};

export default Selection;
