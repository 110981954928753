import React from 'react'
import styled from '@emotion/styled'

const InstContainer = (props) => {
    var variable = props.variable;
    const cnf = props.configuration;
    var value = null;

    if (typeof(props.value) != 'string'){
        value = props.value.toFixed(2); // we limit the number to only 2 decimal places
        value = parseFloat(value);
		var Color = `rgb(0, 0, 0)`;
		if (value > cnf.max) {
			Color =  `rgb(255, 0, 0)`
		}
		else if (value >= cnf.min) {
			Color = 255 * ((value - cnf.min) / (cnf.max - cnf.min));
			Color =  `rgb(${Color}, ${255-Color}, 0)`
		}
		else if (value < cnf.min) {
			Color =  `rgb(0, 255, 0)`
		}
    } else {
        value = props.value;
    }    



    const DataContainer = styled.div`
        margin: 0 auto;
        margin-bottom: 0px;

        background-color: white;

        width: 150%;
        height: 240 px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center; 
        border-radius: 0px;
        line-height: 18pt;
		
		padding: 0px;
		border-color: #000000;
		border-width: 1pt;
		border-style: solid;
    `

    const Tittle = styled.div `
        border: 0 auto;
        width: 100%;
        padding: 0.0em 0px 0.0em 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 0px; 

        font-weight: 500;
        font-size: 14pt;

        background-color: white; 
    `

    const Data = styled.div `
        border: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 0px; 

        font-weight: 500;
        font-size: 22pt;
        padding: 0.25em 0em 0.40em 0em;

        background: ${Color};
    `
 
    const DataErr = styled.div `
        border: 0 auto;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 
        border-radius: 5px; 

        font-weight: 500;
        font-size: 22pt;
        padding: 0.25em 0em 0.40em 0em;

        background-color: white; 
    `
    
    const Timestamp = styled.div `
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around; 
        align-items: center; 

        font-weight: 400;
        font-size: 10pt;
        padding: 0.2em 0em 0.5em 0em;
        /* margin-top: 0pt; */
        line-height: 5pt;

        background: white;
    `
    
    var title = (String(variable)).toUpperCase();

    return (
        <DataContainer>
           <Tittle> {title} </Tittle>
            {typeof(value) != 'string' ? (
                    <>
                        <Timestamp> {props.timestamp} </Timestamp>
                        <Data> {value} {cnf.units} </Data>
                    </>
                ) : (
                    <Data> {value}  </Data>
            )}
        </DataContainer>
    ); 
}
 
export default InstContainer;
