import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled'
import './App.css';
import {Helmet} from 'react-helmet';
 
// Cognito
import {Account} from './components/UserAuthentication/Accounts';
import WholeAuth from './components/UserAuthentication/whole_auth';

// Device selection menu
import Selection from "./components/Selection/choice_menu";

// Layout
import Header from './components/Layout/header';
import Footer from './components/Layout/footer';
import Content from './components/Layout/content';

// Context
import {UserContext} from './components/UserAuthentication/context';
import {SelectContext} from './components/Selection/context';

// Sort function
import Sort from './utils/sort';

// AWS functions
import {scanTable, get_docClient} from './AWS-SDK/AWS';

// Error message
var err_msg = "Default error message!";

let docClient = get_docClient();

function App () {
	
	const App = styled.div`
        position: absolute;
        min-height: 100%;
        min-width: 100%;
    `

    const Container = styled.div`
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 40px;
    `
	
	// Screen state
	const [screen, setScreen] = useState("LOGIN"); // to control the screen to use
	
	// Login states
	const [isUserLogged, setIsUserLogged] = useState(false); // to check if the whole webpage should be shown or not
    const [userEmail, setUserEmail] = useState(''); // user Email
	
	// Selection states
	const [isChoiceSubmitted, setIsChoiceSubmitted] = useState(false); // to check if the user has submitted the device selection
	const [selectedDevices, setSelectedDevices] = useState([]);
	const [isCheckAll, setIsCheckAll] = useState(false);
	
	// Names states
	const [paramNames, setParamNames] = useState({});
	const [devNames, setDevNames] = useState({});
	
	// Devices states
	const [centrals, setCentrals] = useState([]);
	const [peripherals, setPeripherals] = useState([]);
	const [centralPeripherals, setCentralPeripherals] = useState({});
	
	useEffect(()=>{
		// perform actions if it exits login screen
		if (userEmail && screen == "LOGIN"){
			// change to preselection to use loading page if necessary
			setScreen("PRESELECTION");
			// scan users-devicetypes table and find device types (group) assigned
			scanTable("Users_and_DeviceTypes").then(res => {
				if (res.exist){
					var deviceTypes = [];
					for (var i=0; i<res.items.length; i++){
						if (res.items[i].Users == userEmail){
							var deviceTypes_string = res.items[i].DeviceTypes.split(', ');
							for (var a in deviceTypes_string){
								if (deviceTypes_string[a].includes("eContainer")){
									deviceTypes.push(deviceTypes_string[a]);
								}
							}
							break;
						}
					}
					// scan devicetypes-devices table and find centrals assigned
					scanTable("DeviceType_and_Devices").then(res => {
						if (res.exist){
							var centrals_t = [];
							for (var i=0; i<res.items.length; i++){
								if (deviceTypes.includes(res.items[i].DeviceType)){
									res.items[i].Devices.split(', ').forEach(central => {
										if (!centrals_t.includes(central)){
											centrals_t.push(central);
										}
									});
								}
							}
							// scan connections table and find peripherals for each central
							scanTable("eContainer_Connections").then(res => {
								if (res.exist){
									var peripherals_t = [];
									var centralPeripherals_t = {};
									for (var i=0; i<centrals_t.length; i++){
										centralPeripherals_t[centrals_t[i]] = [];
									}
									for (var i=0; i<res.items.length; i++){
										if (centrals_t.includes(res.items[i].Gateway) && (res.items[i].Model < 3 || res.items[i].Model == 5)){
											peripherals_t.push(res.items[i].MAC);
											centralPeripherals_t[res.items[i].Gateway].push(res.items[i].MAC);
										}
									}
									setCentrals(centrals_t);
									setPeripherals(peripherals_t);
									setCentralPeripherals(centralPeripherals_t);
									//scan names table and find devices names
									scanTable("eContainer_Names").then(res => {
										if (res.exist){
											var dev_names = {};
											for (var i=0; i<res.items.length; i++){
												dev_names[res.items[i].MAC] = res.items[i].Name;
											}
											setDevNames(dev_names);
											// scan variables table and find variables names
											scanTable("eContainer_variables").then(res => {
												if (res.exist){
													var param_names = {};
													for (var i=0; i<res.items.length; i++){
														param_names[res.items[i].Var_number] = res.items[i].Parameter;
													}
													setParamNames(param_names);
												}
												// variables table does not exist
												else{
													err_msg = "Variables table does not exist!";
													setScreen("ERROR");
												}
											});
										}
										// names table does not exist
										else{
											err_msg = "Devices Names table does not exist!";
											setScreen("ERROR");
										}
									});
								}
								// devicetypes table does not exist
								else {
									err_msg = "Connections table does not exist!";
									setScreen("ERROR");
								}
							});
						}
						// devicetypes table does not exist
						else {
							err_msg = "Device types table does not exist!";
							setScreen("ERROR");
						}
					});
				}
				// users table does not exist
				else {
					err_msg = "Users table does not exist!";
					setScreen("ERROR");
				}
			});
			setScreen("SELECTION");
		}
	}, [userEmail]);

    return (  
        <div className="App">
			<Helmet>
				<title>Sterna eContainer Dashboard</title>
				<link rel="icon" href="/ec.png" />
			</Helmet>
			{screen != "ERROR" ? (
				<UserContext.Provider value={{isUserLogged, setIsUserLogged, userEmail, setUserEmail} }>
					{isUserLogged && docClient ? (
						<SelectContext.Provider value={{isChoiceSubmitted, setIsChoiceSubmitted, selectedDevices, setSelectedDevices, isCheckAll, setIsCheckAll} }>
							{isChoiceSubmitted ? (
								<App>
									<Header user={userEmail}/>
									<Content devNames={devNames} paramNames={paramNames} />
									<Footer />
								</App>	
							) : (
								<App>
									<Header user={userEmail}/>
									<Selection centrals={centrals} peripherals={peripherals} centralPeripherals={centralPeripherals} devNames={devNames} />
									<Footer />
								</App>
							)}
						</SelectContext.Provider>
					) : (
						<Account>
							<Container>
								<WholeAuth />
							</Container>
						</Account>
					) }
				</UserContext.Provider>
			) : (
				<div> {err_msg} </div>
			)}
        </div>
    );  
}  



export default App;

// https://www.quora.com/What-are-some-good-ways-to-extract-one-single-column-from-a-DynamoDB-table
